import './Toolbar.scss'
import {
  IonButton,
  IonIcon,
  IonImg,
  IonToolbar,
  IonInput,
  IonLabel,
  IonItem, //tabs elements
} from '@ionic/react'
import { menuController } from '@ionic/core'

import React, { useEffect, useRef, useState, useCallback } from 'react'

import menuIcon from '../../assets/images/burger-menu.svg'
import goBackIcon from '../../assets/images/goback.svg'
import searchIcon from '../../assets/images/toolbar-search-icon.svg'
import userIcon from '../../assets/images/toolbar-user-icon.svg'
import { connectToolbar } from './Toolbar.connect'
import { useHistory } from 'react-router'
import { goBackPages, USER_DATA } from '../../config/app.config'
import OneClickToggle from '../OneClickToggle'
import { toastr } from 'react-redux-toastr'
import EventHeader from '../../components/EventHeader/EventHeader'
import LogIn from '../../assets/images/logInIcon.svg'
import SignUp from '../../assets/images/signUpIcon.svg'
import closeSearch from '../../assets/images/closeSearch.svg'
// import Search from '../Search/Search'
import {
  getCurrencyFormat,
  getCurrencyFormatWithoutZeroAfterDot,
} from '../../helpers/getCurrencyFormat.helper'
import CricketIcon from '../../assets/images/sideBarCricket.svg'
import FootballIcon from '../../assets/images/sideBarFooltball.svg'
import TennisIcon from '../../assets/images/sideBarTennis.svg'
import HorseIcon from '../../assets/images/sideBarHorse.svg'
import GreyhoundIcon from '../../assets/images/sideBarGreyhound.svg'
import ExchangeIcon from '../../assets/images/sideBarExchange.svg'
import { showDeposit } from '../../helpers/showDeposit.helper'
import NotificationPopup from '../NotificationPopup/NotificationPopup'
import { isSignedUpWithNumberInBranchHelper } from '../../helpers/isSignedUpWithNumberInBranch.helper'
import config from 'react-redux-toastr/lib/config'
import { BASE_URL, CONFIG } from '../../config/api.config'
import ReactGA from 'react-ga4'
import downloadApk from '../../assets/images/menu-icons/apk_icon_white.svg'

const Toolbar = ({
  auth,
  search = false,
  balance,
  messages,
  oneClick,
  user,
  notifications,
  B2C,
  tabs = false,
  activeTab = undefined,
  contentScrolled = false,
  eventHeader = false,
  noSearch = false,
  alterTitle = false,
  setStyle = () => null,
  menu,
  searchInput,
  inplayPopular,
  searchList,
  logo,
  // openSearch,
  actions,
}) => {
  const history = useHistory()
  const [searchValue, setSearchValue] = useState('')
  const refScroll = useRef(null)
  const [loginOpen, setLoginOpen] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const ref_input = useRef()

  const findPartOfRoute = (route, array) => {
    const res = array.filter((item) => route.indexOf(item) !== -1)
    return !!res.length
  }

  const getDomainWithoutSubdomain = (url) => {
    try {
      const urlParts = new URL(url).hostname.split('.')
      return urlParts
        .slice(0)
        .slice(-(urlParts.length === 4 ? 3 : 2))
        .join('.')
    } catch (error) {
      console.log('55', error)
      return ''
    }
  }

  useEffect(() => {
    // const domainName = window.location.origin;
    const domainName = BASE_URL
    const domainData = getDomainWithoutSubdomain(domainName)

    const data = {
      key: btoa(domainData),
    }

    actions.requestLogo(data)
  }, [])

  const renderNavButton = () => {
    if (findPartOfRoute(history.location.pathname, goBackPages)) {
      return (
        <IonButton
          className="menu-btn"
          mode="md"
          fill="clear"
          onClick={() => {
            // console.log('goback -----------------------------------------');
            history.goBack()
            // if (history.location.pathname !== "/default-page") {
            //   history.goBack()
            // } else {
            //   menuController.open('start')
            // }
          }}
        >
          <IonIcon
            id="menuIcon"
            className="menu-icon go-back-icon"
            src={goBackIcon}
          />
          {/* <IonIcon id="menuIcon" className="menu-icon go-back-icon"/> */}
        </IonButton>
      )
    }
    if (!findPartOfRoute(history.location.pathname, goBackPages)) {
      return (
        <IonButton
          className="menu-btn"
          mode="md"
          fill="clear"
          onClick={() => {
            menuController.open('start')
          }}
        >
          {/* <IonIcon className="menu-icon" /> */}
          <IonIcon className="menu-icon" src={menuIcon} />
        </IonButton>
      )
    }
  }

  useEffect(() => {
    if (!findPartOfRoute(history.location.pathname, goBackPages)) {
      const menuIcon = document.getElementById('menuIcon')
      menuIcon && menuIcon.classList.remove('go-back-icon')
    }
  }, [history.location.pathname, inplayPopular, balance])

  const onSearch = () => {
    if (history.location.pathname.indexOf('/search') == -1) {
      history.push('/search')
    }
  }
  const hableSearchChange = (value) => {
    setSearchValue(value)
  }

  const toggleOneclick = (e) => {
    const settings = {
      ...oneClick,
      active: false,
    }
    if (history.location.pathname.indexOf('/settings') === -1)
      actions.requestSaveOneClickSettings(user.memberCode, settings)
  }

  //on search field change thunk
  useEffect(() => {
    actions.setSearchValue(searchValue)
  }, [searchValue])

  //hide part of header on scroll
  useEffect(() => {
    if (contentScrolled) {
      setStyle({
        transform: `translate3d(0px, -${refScroll.current.clientHeight}px, 0px)`,
        // height: '50px'
      })
    } else {
      setStyle({
        transform: 'none',
      })
    }
  }, [contentScrolled])

  const getCurrentBalance = () => {
    if (balance) {
      if (balance.useBWallet) {
        return getCurrencyFormatWithoutZeroAfterDot(
          balance?.bWallet?.availBalance.toFixed(0)
        )
      } else if (balance.useSWallet) {
        return getCurrencyFormatWithoutZeroAfterDot(
          balance?.sWallet?.availBalance.toFixed(0)
        )
      } else if (balance.useCWallet) {
        return getCurrencyFormatWithoutZeroAfterDot(
          balance?.cWallet?.availBalance.toFixed(0)
        )
      } else if (balance?.availBalance) {
        return getCurrencyFormatWithoutZeroAfterDot(
          balance?.availBalance.toFixed(0)
        )
      } else return ''
    }
    return 0
  }

  let userData = localStorage.getItem(USER_DATA)
  let user_Data = JSON.parse(userData)

  const handleDetosit = (val) => {
    if (user_Data?.is_demo) {
      toastr.error('', 'Please login from your read ID.')
      return
    }
    history.push(val)
  }

  const rightMenu = () =>
    auth ? (
      <>
        {B2C && (
          <IonButton
            className="user-profile-deposit"
            mode="md"
            onClick={() => {
              ReactGA.event({
                label: 'Deposit_Buttons',
                category: 'User-Click',
                action: 'Header_Deposit_Button',
              })
              handleDetosit('/deposit')
            }}
          >
            <div className="loginSignup">
              <div className="loginSignup-text">Deposit</div>
            </div>
          </IonButton>
        )}
        {isSignedUpWithNumberInBranchHelper() && (
          <IonButton
            className="user-profile-deposit"
            mode="md"
            onClick={() => {
              window.open(JSON.parse(localStorage.getItem('user_data'))?.wa)
            }}
          >
            <div className="loginSignup">
              <div className="loginSignup-text">Deposit</div>
            </div>
          </IonButton>
        )}

        <IonButton
          className="user-profile-btn"
          mode="md"
          onClick={() => {
            menuController.open('end')
          }}
        >
          <div className="user-profile-btn__wrapper">
            <IonIcon
              className="user-profile-btn__icon"
              slot="start"
              icon={userIcon}
            />
            <span>
              {balance?.balance &&
                getCurrencyFormat(Math.floor(balance?.balance))}
            </span>
          </div>
        </IonButton>
      </>
    ) : (
      <>
        <IonButton
          className="user-profile-btnLog"
          mode="md"
          onClick={() => {
            actions.setLoginNew(true)
          }}
        >
          <div className="loginSignup">
            <div className="loginSignup-text">Log in</div>
          </div>
        </IonButton>

        {B2C && (
          <IonButton
            className="user-profile-btn-sign-up"
            mode="md"
            onClick={() => {
              actions.setTab(2)
              // window.open(CONFIG.whatsappLink)
              // window.location.href ='https://www.lotus365.com/?sign'
            }}
          >
            <div className="loginSignup">
              <div className="loginSignup-text">Sign up</div>
            </div>
          </IonButton>
        )}
      </>
    )
  const renderSearch = () => {
    if (noSearch) return rightMenu()
    return !search ? (
      <div
        className="ion-toolbar__right"
        // style={!search ? {} : { display: 'none' }}
      >
        <IonImg
          className="search-icon"
          src={downloadApk}
          id="focusButton2"
          onClick={() => {
            ReactGA.event({
              label: 'Download_App',
              category: 'User-Click',
              action: 'Toolbar_Download_App',
            })
            window.location.href = CONFIG.downloadAppLink
          }}
        />

        <IonImg
          className="search-icon"
          src={searchIcon}
          id="focusButton"
          // onClick={onSearch}
          onClick={() => {
            setOpenSearch(true)
          }}
        />

        {rightMenu()}
      </div>
    ) : (
      <div
        className="ion-toolbar__search"
        // style={{transition: "all 0.2ms"}}
      >
        <input
          id="input"
          name="input"
          ref={ref_input}
          value={searchValue}
          onChange={(e) => {
            hableSearchChange(e.target.value)
          }}
          className={`ion-toolbar__search__input ${
            searchValue ? 'changed' : ''
          }`}
          placeholder="Search Events"
          autofocus="true"
        />
      </div>
    )
  }

  const renderLogo = () => {
    if (alterTitle)
      return <div className="toolbar-altertitle">{alterTitle}</div>
    if (!search)
      return (
        <div
          analytics-on=""
          style={{
            backgroundImage: `url(${logo})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '144px',
            height: '52px',
          }}
          onClick={() => {
            if (history.location.pathname === '/default-page') {
              window.location.reload()
            } else {
              history.push('/default-page')
            }
          }}
        ></div>
      )
    // if (!search) return <div analytics-on="" className="toolbar-logo" onClick={() => { history.push('/') }}></div>
  }
  // Search events start ----------------------------------------------------------
  const [menus, setMenus] = useState([])
  const [result, setResult] = useState([])

  const competionNames = (playerNames, eventTypeId) => {
    let eventsArray = menu.find((item) => item.id == eventTypeId)
    let competitionName = eventsArray?.children.find((el) =>
      el.children.find((item) => item.id === playerNames)
    )
    return competitionName?.name
  }

  // useEffect(() => {
  //     if (menu && (!menus || menus.length === 0)) {
  //         // setMenus(menu)
  //     }
  // }, [menu, searchInput])

  useEffect(() => {
    actions.requestSearchList()
  }, [])

  useEffect(() => {
    setMenus(searchList)
  }, [searchList])

  // useEffect(() => {
  //     if (history.location.pathname === '/search') {
  //         setMenus(menu)
  //     }
  // }, [history.location.pathname])

  const gotoEvent = (item) => {
    if (item.eventTypeId == '77777') {
      history.push(`/casino_live/${item.id}`)
      setOpenSearch(false)
      setSearchValue('')
    } else {
      localStorage.setItem('Event', JSON.stringify(item))
      history.push(`/event-page/${item.eventTypeId}/${item.match_id}`)
      setOpenSearch(false)
      setSearchValue('')
    }
  }

  useEffect(() => {
    const result = []
    if (menus)
      menus.filter(
        (event) =>
          event.match_name
            .toLowerCase()
            .indexOf(searchInput.value.toLowerCase()) !== -1 &&
          result.push({ ...event, eventTypeId: event.sport_id })
      )
    setResult(result)
  }, [menus, searchInput])

  const renderResults = () => {
    if (searchInput.value.length > 2) {
      return result && result.length ? (
        <div className="searchShadow">
          <div style={{ background: '#fff' }} className="Search__results">
            {result.map((eventElement, id) => (
              // <div className="Search__results__item" key={id} onClick={() => gotoEvent(eventElement)}>
              //     <span>{eventElement.name}</span>
              //     {/* <IonNote className="Search__results__item__type">event</IonNote> */}
              // </div>)

              <div
                className="Search__results__item"
                key={id}
                onClick={() => gotoEvent(eventElement)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '6px 16px 6px 19px',
                }}
              >
                <div style={{ display: 'flex', marginRight: '10px' }}>
                  <img
                    style={{ height: '19px' }}
                    loading={'lazy'}
                    src={
                      eventElement.eventTypeId == 4
                        ? CricketIcon
                        : eventElement.eventTypeId == 1
                        ? FootballIcon
                        : eventElement.eventTypeId == 2
                        ? TennisIcon
                        : eventElement.eventTypeId == 7
                        ? HorseIcon
                        : eventElement.eventTypeId == 4339
                        ? GreyhoundIcon
                        : eventElement.eventTypeId == 1444001
                        ? ExchangeIcon
                        : ''
                    }
                    alt=""
                  />
                  <div className="searchList-text-info">
                    <span>
                      {eventElement.match_name.length > 25
                        ? eventElement.match_name.slice(0, 25) + '...'
                        : eventElement.match_name}
                    </span>
                    <div style={{ marginTop: '5px', fontSize: '12px' }}>
                      {competionNames(
                        eventElement.id,
                        eventElement.eventTypeId
                      )}
                    </div>
                  </div>
                </div>
                <span>
                  {/* {new Date(eventElement.startTime).toLocalDateString({
                      day:'numeric',
                      month: 'numeric',
                      year: 'numeric',
                      })} */}
                  {/* 28.08.2022 */}
                </span>
                {/* <IonNote className="Search__results__item__type">event</IonNote> */}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="searchShadow">
          <div className="Search__empty">
            {result &&
              searchInput.value &&
              searchInput.value.length > 2 &&
              `No events found matching for '${searchInput.value}'`}
          </div>
        </div>
      )
    } else return ''
  }
  // Search events end ----------------------------------------------------------
  return (
    <>
      <NotificationPopup messages={messages} actions={actions} />
      <IonToolbar className="ion-toolbar onscroll">
        <div ref={refScroll}>
          {notifications && messages && messages.length > 0 ? (
            <div className="ion-toolbar__wrapper notification">
              <div className="notification__container">
                <div className="notification__container__marquee">
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className="notification__container__message"
                    >
                      {message.heading}
                    </div>
                  ))}
                </div>
              </div>
              <span
                className="notification__close"
                onClick={() => actions.setNotifications(false)}
              >
                X
              </span>
            </div>
          ) : (
            ''
          )}

          <div className="ion-toolbar__wrapper main">
            <div className="ion-toolbar__left">
              {renderNavButton()}
              {renderLogo()}
            </div>

            {noSearch && rightMenu()}
            {
              <>
                <div className="ion-toolbar__right">
                  {/* <IonImg className="download-icon"
                          src={downloadApk}
                          id="focusButton2"
                    // onClick={onSearch}
                          onClick ={() => {
                            window.location.href = CONFIG.downloadAppLink
                          }}
                  /> */}
                  <IonImg
                    className="search-icon"
                    src={searchIcon}
                    onClick={() => {
                      setOpenSearch(true)
                      ref_input.current.focus()
                    }}
                  />
                  {rightMenu()}
                </div>

                <div
                  className={'ion-toolbar__search'}
                  style={openSearch ? { top: 0 } : { height: 0 }}
                >
                  <input
                    ref={ref_input}
                    value={searchValue}
                    onChange={(e) => {
                      hableSearchChange(e.target.value)
                    }}
                    className={`ion-toolbar__search__input ${
                      searchValue ? 'changed' : ''
                    }`}
                    placeholder="Search Events"
                    // autofocus="true"
                  />
                  {openSearch && (
                    <img
                      loading={'lazy'}
                      className="closeCross"
                      src={closeSearch}
                      alt=""
                      onClick={() => {
                        setOpenSearch(false)
                      }}
                    />
                  )}
                </div>
              </>
            }
          </div>
          {oneClick.active &&
            history.location.pathname.indexOf('/settings') === -1 && (
              <div className="ion-toolbar__wrapper one-click">
                <OneClickToggle
                  className="toolbar-toggle"
                  checked={oneClick.active}
                  onIonChange={toggleOneclick}
                />
                <div className="one-click-text">
                  1 CLICK BET IS ON - ACTIVE STAKE{' '}
                  {oneClick.settings[oneClick.selected - 1]}
                </div>
              </div>
            )}
        </div>
        {eventHeader && <EventHeader />}
        {tabs && (
          <div className="ion-toolbar__wrapper toolbar__tabs">
            <div className="toolbar__tabs__bar" slot="bottom">
              {tabs.map(
                (tab, index) =>
                  tab && (
                    <div
                      className={`toolbar__tabs__bar__tab ${
                        activeTab == index ? 'active' : ''
                      }`}
                      onClick={tab.setTab}
                      key={index}
                    >
                      <IonLabel>{tab.label}</IonLabel>
                      {tab.icon}
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </IonToolbar>
      {openSearch && <div className="searchValues">{renderResults()}</div>}
    </>
  )
}

export default connectToolbar()(Toolbar)
