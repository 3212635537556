import React from 'react'

import { useHistory } from 'react-router'
import { IonButton } from '@ionic/react'
import { connectVerification } from './verification.connect'

import walletD from '../../assets/images/verification/walletD.svg'
import walletW from '../../assets/images/verification/walletW.svg'
import './Verification.scss'
import { USER_DATA } from '../../config/app.config'
import { toastr } from 'react-redux-toastr'

const Verifbuttons = ({ auth, actions }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory()

  let userData = localStorage.getItem(USER_DATA)
  let user_Data = JSON.parse(userData)

  const handleDetosit = (val) => {
    if (user_Data?.is_demo) {
      toastr.error('', 'Please login from your read ID.')
      return
    }
    history.push(val)
  }

  return (
    <div className="verification">
      <div className="verification__main">
        <div className="verification__main-new">New</div>
        <div className="verification__main-title">
          Now Deposit and Withdraw directly from Our website
        </div>
        <div className="verification__main-buttons">
          <IonButton
            onClick={() =>
              auth ? handleDetosit('/withdrawal') : actions.setLoginNew(true)
            }
            class="verification-button"
            mode="md"
          >
            <img src={walletW} alt="walletW" />
            Withdraw
          </IonButton>
          <IonButton
            onClick={() =>
              auth ? handleDetosit('/deposit') : actions.setLoginNew(true)
            }
            class="verification-button deposit"
            mode="md"
          >
            <img src={walletD} alt="walletD" />
            Deposit
          </IonButton>
        </div>
      </div>
    </div>
  )
}
export default connectVerification()(Verifbuttons)
