import React, { useEffect, useState } from 'react'
import './SportMarketStandard.scss'
import OddButton from '../OddButton/oddbutton'
import BetPlacing from '../BetPlacing/BetPlacing'
import { connectSportMarketStandard } from './SportMarketStandard.connect'
import timerIcon from '../../assets/images/timer.svg'
import { useHistory } from 'react-router'

import { useDispatch, useSelector } from 'react-redux'
import {
  addfavoritesSportMarkets,
  clearfavoritesSportMarkets,
  addFavoritesAll,
  clearFavoritesAll,
} from '../../store/favorites/favorites.slice'

import StarIcon from '../../assets/images/sideBarStar.svg'
import StarIconFilled from '../../assets/images/sideBarStarFilled.svg'
import fasterBetAcceptanceIcon from '../../assets/images/fasterBetAcceptanceIcon.svg'
import noCommissionIcon from '../../assets/images/noCommissionIcon.svg'
import SportMarketStandardPopUp from './SportMarketStandardPopUp/SportMarketStandardPopUp'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'
import { getPlacingText } from '../../helpers/betPlacing.helper'
import { toastr } from 'react-redux-toastr'
import { requestBetPlacingStart } from '../../store/betPlacing/betPlacing.thunks'
import {
  setSportLadderId,
  setSportLadderShow,
  setSportLadderTitle,
} from '../../store/sportEvent/sportEvent.slice'
import { requestSportLadder } from '../../store/sportEvent/sportEvent.thunks'

const SportMarketStandard = ({
  data,
  betPlacing,
  exposure,
  actions,
  isRacing = false,
  showHeader = true,
  showJockeyName = false,
  sortRunners = false,
  showWinner = false,
  full,
  elementsParams,
  fancy = false,
  sportLimits,
}) => {
  const dispatch = useDispatch()
  const [besSettings, setBesSettings] = useState(null)
  const [cashOutLoading, setCashOutLoading] = useState(null)
  const isFancy = fancy

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth
  let sportid =
    localStorage.getItem('page') && localStorage.getItem('page')?.split('/')[2]
  useEffect(() => {
    if (isFancy && width >= 1024) {
      // dispatch(requestBetPlacingStart(data?.id, data?.runners[0]?.id, true))
      //   .then((res) => setBesSettings(res?.data?.result))
    }
  }, [data.id, isFancy, width])
  const sendCahsOutRequest = (marketId, delay) => {
    actions.setDelay(delay)
    actions.setCashOutId(marketId)
    actions.setIsCashOut(true)
    actions.setBetPlacingPopoverStage(false)
    actions.setShowBetPlacingPopover(true)
    // actions.requestCashOut(marketId).then((res) => {
    //   actions.setShowBetPlacingPopover(false);
    //   const sizeMatched = res.data.result?.result?.placed?.[0].sizeMatched;
    //   if (sizeMatched > 0) {
    //     toastr.success("", "Cash-out successful");
    //   }
    //   // cancel()
    // }).catch((e) => {
    //   // cancel()
    //   actions.setShowBetPlacingPopover(false);
    //   if (e[0] && e[0][0] && e[0][0].description) toastr.error("", e[0][0].description);
    //   else toastr.error("", "Something went wrong");
    // });
  }

  const renderBetPlacing = (runner) => {
    const status = data.GameStatus || data.is_active || data.status
    const statusLabel = data.statusLabel

    if (
      betPlacing.market === data.fancy_id &&
      // betPlacing.runner === runner.id &&
      status &&
      (!statusLabel || betPlacing.btype != 'casino') &&
      width < 1024
    ) {
      return <BetPlacing />
    }
    return null
  }

  const [clicked, setClicked] = useState(null)
  const [winner, setWinner] = useState()

  const renderOdds = (item, runner) => {
    const status = data.GameStatus || data.display_message
    const statusLabel = data.statusLabel

    if (status && !!status.localeCompare('OPEN')) {
      return (
        <div className="SportEvent__market__status">{status.toUpperCase()}</div>
      )
    }
    if (!isFancy && width >= 1024) {
      return (
        <>
          {
            <OddButton
              market={data}
              isBack={true}
              oddData={{ ...item?.ex?.availableToBack[2], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={2}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
          {
            <OddButton
              market={data}
              isBack={true}
              oddData={{ ...item?.ex?.availableToBack[1], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={1}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
          <OddButton
            market={data}
            isBack={true}
            oddData={{ ...item?.ex?.availableToBack[0], runner }}
            showEmpty
            centered
            showBlock={isRacing ? true : false}
            marketNum={0}
            setClicked={setClicked}
            clicked={clicked}
          />
          <OddButton
            market={data}
            isBack={false}
            oddData={{ ...item?.ex?.availableToLay[0], runner }}
            showEmpty
            centered
            showBlock={isRacing ? true : false}
            marketNum={0}
            setClicked={setClicked}
            clicked={clicked}
          />
          {
            <OddButton
              market={data}
              isBack={false}
              oddData={{ ...item?.ex?.availableToLay[1], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={1}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
          {
            <OddButton
              market={data}
              isBack={false}
              oddData={{ ...item?.ex?.availableToLay[2], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={2}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
        </>
        // <></>
      )
    }
    return (
      <>
        {statusLabel &&
        statusLabel == 'Ball Running' &&
        data.mtype == 'INNINGS_RUNS' ? (
          <div className="SportEvent__market__statuslabel">{statusLabel}</div>
        ) : null}
        {isFancy && (
          // {size: "", price: ""}
          <OddButton
            market={data}
            isBack={false}
            oddData={{
              ...{
                size: data.LaySize1 || data.session_size_no,
                price: data.LayPrice1 || data.session_value_no,
              },
              runner,
            }}
            showEmpty
            centered
            isFancy={isFancy}
          />
        )}
        <OddButton
          market={data}
          isBack={true}
          oddData={{
            ...{
              size: data.BackSize1 || data.session_size_yes,
              price: data.BackPrice1 || data.session_value_yes,
            },
            runner,
          }}
          showEmpty
          centered
          isFancy={isFancy}
        />
        {!isFancy && (
          <OddButton
            market={data}
            isBack={false}
            oddData={{ ...item?.ex?.availableToLay[0], runner }}
            showEmpty
            centered
          />
        )}
      </>
    )
  }

  const openLadder = (name, id) => {
    if (exposure && exposure[id]) {
      dispatch(setSportLadderShow(true))
      dispatch(setSportLadderTitle(name))
      dispatch(setSportLadderId(id))
      dispatch(requestSportLadder(id))
    }
  }

  const renderInningIcon = () => {
    return (
      <span
        onClick={() => openLadder(data.name, data.fancy_id)}
        className={`SportMarketStandard__inningIcon ${
          exposure && exposure[data.fancy_id] ? '' : 'disabled'
        }`}
      />
    )
    // return null;
  }
  const renderExposure = (runnerId) => {
    let number = 0,
      calced
    // const isFancy = data.mtype == "INNINGS_RUNS" && data.btype == "LINE"
    const pnlId = data.fancy_id
    if (exposure && exposure[pnlId]) number = exposure[pnlId]
    if (betPlacing.market === data.fancy_id) {
      if (betPlacing.runner === runnerId) {
        if (betPlacing.isBack) {
          // const profit = isFancy ? (betPlacing.stake * betPlacing.priceActual / 100).toFixed(2) : (betPlacing.stake * betPlacing.priceActual - betPlacing.stake).toFixed(2)
          const profit = isFancy
            ? betPlacing.stake
            : (
                betPlacing.stake * betPlacing.priceActual -
                betPlacing.stake
              ).toFixed(2)
          calced = (isFancy ? 0 : number) + Number(profit) * (isFancy ? -1 : 1)
        } else {
          const profit = isFancy
            ? betPlacing.stake * (betPlacing.priceActual / 100)
            : (
                betPlacing.stake * betPlacing.priceActual -
                betPlacing.stake
              ).toFixed(2)
          calced = (isFancy ? 0 : number) + Number(profit) * -1
        }
      } else {
        calced = betPlacing.isBack
          ? number - Number(betPlacing.stake)
          : number - Number(betPlacing.stake) * -1
        // calced = number - Number(betPlacing.stake)
      }
    }

    const preCalced = isFancy && number == 0 ? 'Max exposure: ' : ''
    if (number || calced)
      return (
        <div className="SportEvent__market__title__exposure">
          {number ? (
            <div className={`${number > 0 ? 'positive' : 'negative'}`}>
              {getCurrencyFormat(number)}
            </div>
          ) : null}
          {preCalced}
          {(calced || (number && calced == 0)) && (
            <div className={`${calced > 0 ? 'positive' : 'negative'}`}>
              » {getCurrencyFormat(calced)}
            </div>
          )}
        </div>
      )
    return null
  }

  // get eventTypeId
  const history = useHistory()
  const eventTypeId = history?.location?.pathname?.split('/')[2]
  // const eventId = history?.location?.pathname?.split("/")[3];
  // end get eventTypeId

  const [activeIcon, setActiveIcon] = useState()

  const toggleStar = () => {
    setActiveIcon((prevCheck) => !prevCheck)
  }

  const favoritesState = useSelector((state) => state.favorites)

  localStorage.setItem('favorites', JSON.stringify(favoritesState))

  // const isFavorite = () => {
  //   if (
  //     favoritesState?.sportMarkets?.length > 0 &&
  //     data &&
  //     favoritesState?.sportMarkets?.filter((el) => el?.id === data?.id).length
  //   ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  const isFavorite = () => {
    if (
      favoritesState?.favoritesAll?.length > 0 &&
      data &&
      favoritesState?.favoritesAll?.filter((el) => el?.id === data?.id).length
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (isFavorite()) {
      setActiveIcon(true)
    } else setActiveIcon(false)
  }, [favoritesState, data])

  // const handleFavorite = (id, data) => {
  //   // console.log('favitem', item);
  //   if (activeIcon) {
  //     console.log('if active clear')
  //      dispatch(clearfavoritesSportMarkets(data))
  //   } else {
  //     dispatch(addfavoritesSportMarkets(data))
  //   }
  // }

  const handleFavorite = (id, item) => {
    if (activeIcon) {
      dispatch(clearFavoritesAll(item))
    } else {
      dispatch(addFavoritesAll(item))
    }
  }

  const gotoEvent = () => {
    if (data.eventTypeId == '77777')
      history.push(`/casino_live/${data.event.id}`)
    else history.push(`/event-page/${data.eventTypeId}/${data.event.id}`)
  }
  const [openPopUp, setOpenPopUp] = useState(false)
  const [popUpTitle, setPopUpTitle] = useState(null)

  // let finish = new Date(data?.start)

  const calcMinutes = (time) => {
    return ((new Date(time).getTime() - new Date().getTime()) / 60000).toFixed(
      0
    )
  }

  if (width >= 1024) {
    return (
      <section
        className={`SportMarketStandard ${
          data?.runners?.length < 2 ? 'short' : ''
        } ${
          sportid === '1' ||
          sportid === '2378962' ||
          sportid === '2' ||
          sportid === '4' ||
          sportid === '7' ||
          sportid === '4339'
            ? 'moreOdds'
            : ''
        } `}
      >
        {showHeader && !isFancy && (
          <div className="SportMarketStandard__header">
            <div
              className={`SportEvent__market__title ${
                data?.betDelay >= 1 ? 'withDelay' : ''
              }`}
            >
              {history.location.pathname === '/favouritesPage' ? (
                <img
                  style={{ marginLeft: '0', cursor: 'pointer' }}
                  onClick={() => {
                    // dispatch(clearfavoritesSportMarkets(data))
                    dispatch(clearFavoritesAll(data))
                  }}
                  className="starImg"
                  src={StarIconFilled}
                  alt="star icon"
                  loading={'lazy'}
                />
              ) : (
                <img
                  style={{ marginLeft: '0', cursor: 'pointer' }}
                  onClick={() => {
                    toggleStar(data)
                    handleFavorite(data.id, data)
                  }}
                  className="starImg"
                  src={activeIcon ? StarIconFilled : StarIcon}
                  alt="star icon"
                  loading={'lazy'}
                />
              )}
              <span
                style={{ cursor: 'pointer' }}
                className="SportEvent__market__title-name"
                onClick={gotoEvent}
              >
                {data?.name}
                {data?.market_name === 'BOOKMAKER'
                  ? 'Bookmaker'
                  : data?.id?.includes('BR') &&
                    data?.market_name.includes('1st innings o')
                  ? data?.market_name.replace('1st innings o', 'O')
                  : data?.id?.includes('BR') &&
                    data?.market_name.includes('1st innings -')
                  ? data?.market_name
                  : data?.market_name}
              </span>
              {/* {data?.runners?.length > 0 && !data?.id.endsWith("_BR") && !data?.id.endsWith("SB") && !data?.id.endsWith("_BR") &&
                <button className="cashOutButton" onClick={() => sendCahsOutRequest(data.id, data.betDelay)}> CASH
                  OUT</button>} */}

              {/* {data?.betDelay >= 1 ? (
                data && data?.id?.includes("_SB") ?
                  (<div className="SportMarketStandard__title-timer">
                    <div className="hover-before">
                      <img
                        className="SportMarketStandard__title-timer-fasterBetAcceptanceIcon"
                        src={fasterBetAcceptanceIcon}
                        alt="faster Bet Acceptance Icon"
                        loading={"lazy"}
                      />
                    </div>

                    <div className="hover-after">
                      <img
                        className="SportMarketStandard__title-timer-noCommissionIcon"
                        src={noCommissionIcon}
                        alt="no Commission Icon"
                        loading={"lazy"}
                      />
                    </div>
                  </div>) :
                  (<div className="SportMarketStandard__title-timer">
                    <img
                      className="SportMarketStandard__title-timer-img"
                      src={timerIcon}
                      alt="timer icon"
                      loading={"lazy"}
                    />
                    <span className="SportMarketStandard__title-timer-delay">
                  {data.betDelay}s
                </span>
                  </div>)
              ) : null} */}
            </div>

            {data?.status !== 'SUSPENDED' && (
              <div className="SportMarketStandard__header__action-wrapper">
                <div className="SportMarketStandard__header__action">Back</div>
                <div className="SportMarketStandard__header__action">Lay</div>
              </div>
            )}
          </div>
        )}
        <div className="SportMarketStandard__markets">
          {data?.status === 'SUSPENDED' &&
            (data?.eventTypeId === '1' ||
              data?.eventTypeId === '2' ||
              data?.eventTypeId === '4' ||
              data?.eventTypeId === '7' ||
              data?.eventTypeId === '2378962' ||
              data?.eventTypeId === '4339') && (
              <div className="SportMarketStandard__markets-suspended">
                <span>SUSPENDED</span>
              </div>
            )}

          <div key={data?.selectionId} className="SportEvent__market">
            {/* FANCY SHOULD BE HERE */}
            <div
              className={`SportEvent__market__row ${
                data?.GameStatus?.toUpperCase() ||
                data.display_message?.toUpperCase()
              }  ${data?.GameStatus} ${
                data?.metadata?.JOCKEY_NAME ? 'showJockeyName' : ''
              }`}
            >
              <div className="SportEvent__market__title">
                <div
                  className={`SportEvent__market__title__text ${
                    data?.betDelay ? 'withDelay' : ''
                  }`}
                >
                  <div>
                    <p>
                      {data.metadata?.CLOTH_NUMBER && (
                        <span className={`SportEvent__market-item-number`}>
                          {data.metadata?.CLOTH_NUMBER &&
                            data.metadata?.CLOTH_NUMBER}
                        </span>
                      )}
                      <span className="SportEvent__market-item-name">
                        {/* HERE should be fancy */}
                        {data?.name || data?.fancy_name}
                      </span>
                      {data?.tabGroupName === 'Fancy' && data?.betDelay && (
                        <div className="SportMarketStandard__title-timer">
                          <img
                            className="SportMarketStandard__title-timer-img"
                            src={timerIcon}
                            alt="timer icon"
                            loading={'lazy'}
                          />
                          <span className="SportMarketStandard__title-timer-delay">
                            {data.betDelay}s
                          </span>
                        </div>
                      )}
                    </p>
                    {showJockeyName && data?.metadata?.JOCKEY_NAME && (
                      <p>
                        {data.metadata?.STALL_DRAW && (
                          <span className={`SportEvent__market-item-number`}>
                            (
                            {data.metadata?.STALL_DRAW &&
                              data.metadata?.STALL_DRAW}
                            )
                          </span>
                        )}
                        <span
                          className={`jockeyName__name  ${
                            !data.metadata?.STALL_DRAW &&
                            showJockeyName &&
                            data?.metadata?.JOCKEY_NAME
                              ? 'STALL_DRAW-missed'
                              : ''
                          } `}
                        >
                          {showJockeyName &&
                            data?.metadata?.JOCKEY_NAME &&
                            data?.metadata?.JOCKEY_NAME}
                          {showJockeyName &&
                            !data?.metadata?.JOCKEY_NAME &&
                            eventTypeId &&
                            eventTypeId === '7' &&
                            'Non Runner'}
                        </span>
                      </p>
                    )}
                  </div>
                  {renderExposure(data?.id)}
                </div>
                {data?.GameStatus === 'WINNER' && (
                  <span className={`SportEvent__market-showStatus`}>
                    Winner
                  </span>
                )}
                {data?.GameStatus === 'REMOVED' &&
                  eventTypeId &&
                  eventTypeId === '4339' && (
                    <span
                      className={`SportEvent__market-showStatus greyhound-removed`}
                    >
                      Removed
                    </span>
                  )}
                {data?.GameStatus === 'REMOVED' &&
                  eventTypeId &&
                  eventTypeId === '7' &&
                  data?.GameStatus !== 'SUSPENDED' && (
                    <span
                      className={`SportEvent__market-showStatus horse-removed`}
                    >
                      Non Runner {data?.adjustmentFactor?.toFixed(2)}%
                      <span
                        style={{
                          display: 'inline-block',
                          marginLeft: '8px',
                        }}
                      >
                        {new Date(data?.removalDate)
                          .toLocaleString('en-GB', {
                            weekday: 'short',
                            day: 'numeric',
                            month: 'short',
                            hour: 'numeric',
                            minute: 'numeric',
                          })
                          .replaceAll(',', ' ')}
                      </span>
                    </span>
                  )}
                {renderInningIcon()}
              </div>
              {/*{renderOdds(item, runner)}*/}
              {isRacing && data && data?.status === 'ACTIVE'
                ? renderOdds(data)
                : ''}
              {/*{item.status === "REMOVED" &&*/}
              {/*  <div className="SportEvent__market-showStatus-wrapper">*/}
              {/*    <span className={`SportEvent__market-showStatus removed`}>{item?.metadata?.JOCKEY_NAME} {item?.adjustmentFactor?.toFixed(2)}%</span>*/}
              {/* </div>*/}
              {/*}*/}
              {!isRacing && (
                <div
                  className={`SportEvent__market__odds-wrapper ${
                    isFancy ? 'fancy' : ''
                  }`}
                  style={{ display: 'flex' }}
                >
                  {renderOdds(data)}
                  {isFancy && (
                    <div className="SportEvent__market__odds-wrapper-max-bets">
                      {(data.session_live_odds_validation ||
                        (sportLimits &&
                          sportLimits.data &&
                          sportLimits.data?.session &&
                          (sportLimits?.check_event_limit ||
                            sportLimits.data?.session[data?.fancy_id]))) && (
                        <span>
                          Min:{' '}
                          {getCurrencyFormat(
                            data.session_live_odds_validation
                              ? data.Min
                              : sportLimits?.check_event_limit
                              ? sportLimits.data?.session[data?.fancy_id].session_min_stack
                              : sportLimits.data?.session.session_min_stack
                          )}
                        </span>
                      )}
                      {(data.session_live_odds_validation ||
                        (sportLimits &&
                          sportLimits.data &&
                          sportLimits.data?.session &&
                          (sportLimits?.check_event_limit ||
                            sportLimits.data?.session[data?.fancy_id]))) && (
                        <span>
                          Max:{' '}
                          {getCurrencyFormat(
                            data.session_live_odds_validation
                              ? data.Max
                              : sportLimits?.check_event_limit
                              ? sportLimits.data?.session[data?.fancy_id].session_max_stack
                              : sportLimits.data?.session.session_max_stack
                          )}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            {renderBetPlacing(data)}
          </div>

          {/* END for fancy */}
        </div>
      </section>
    )
  }

  return (
    <section
      className={`SportMarketStandard ${
        data?.runners?.length < 2 ? 'short' : ''
      }`}
    >
      {/*{(data?.name.includes('Winner (incl. super over') || data?.name.includes('1x2')) && calcMinutes(data?.start) >= 0 && calcMinutes(data?.start) <= 59 && <div className='SportMarketStandard__timer'>Starts in {calcMinutes(data?.start)} mins</div>}*/}
      {/*{(data?.name.includes('Winner (incl. super over') || data?.name.includes('1x2')) && calcMinutes(data?.start) > 59 && <div className='SportMarketStandard__timer'>{new Date(data?.start).toLocaleString("en-GB")}</div>}*/}
      {/* {data?.name.includes('1x2') && calcMinutes(data?.start) >= 0 && calcMinutes(data?.start) <= 59 && <div className='SportMarketStandard__timer'>Starts in {calcMinutes(data?.start)} mins</div>}
      {data?.name.includes('1x2') && calcMinutes(data?.start) > 59 && <div className='SportMarketStandard__timer'>{new Date(data?.start).toLocaleString("en-GB")}</div>} */}

      {data?.market_name?.includes('Winner (incl. super over') ||
      data?.market_name === '1x2' ||
      data?.market_name?.includes('To Win The Toss') ? (
        <>
          {/* <div className="SportMarketStandard__winner">
        {(sortRunners
          ? data?.runners
              ?.slice()
              .sort((a, b) => a.sort - b.sort)
              .slice(0, full ? undefined : 3)
          : data?.runners
        )?.map((item) => {
          const runner = {
            id: item?.id,
            name: item?.name,
          }
          return (

        <div key={runner.id}>
          <div style={data?.market_name.includes('Winner (incl. super over') ? {width: '145px'} : data?.market_name.includes('To Win The Toss') ?  {width: '145px'} : {}}className='SportMarketStandard__winner-block'>
            <div className='SportMarketStandard__winner-block-name'>{runner.name}</div>
            {!item?.back[0]?.price ?
              <div className={`SportMarketStandard__winner-block${data?.market_name.includes('Winner (incl. super over') ? '-suspended' : data?.market_name.includes('To Win The Toss') ? '-suspended' : '-suspended1'}`}>
                <span>SUSPENDED</span>
              </div>
            :
            <div className={`SportMarketStandard__winner-block${data?.market_name.includes('Winner (incl. super over') ? '-odds' : data?.market_name.includes('To Win The Toss') ? '-odds' : '-odds1'}`} onClick={() => setWinner(item)}>
              <OddButton
                market={data}
                isBack={true}
                oddData={{...item.back[0], runner}}
                showEmpty
                centered
              />
            </div>
            }
          </div>
          <div className={`SportMarketStandard__winner-block${data?.market_name.includes('Winner (incl. super over') ? '-exposure' : data?.market_name.includes('To Win The Toss') ? '-exposure' : '-exposure1'}`}>
            {renderExposure(runner?.id)}
          </div>
        </div>
          )}
          )}
      </div>
      {renderBetPlacing(winner)} */}
        </>
      ) : (
        <div className="SportMarketStandard__markets">
          <div key={data?.id} className="SportEvent__market">
            {/* FANCY MAYBE HERE TOO */}
            <div
              className={`SportEvent__market__row ${
                data && data?.GameStatus && data?.GameStatus.toUpperCase()
              }  ${data?.GameStatus} ${
                data?.metadata?.JOCKEY_NAME ? 'showJockeyName' : ''
              }`}
            >
              <div className="SportEvent__market__title">
                <div className={`SportEvent__market__title__text `}>
                  <div>
                    <p>
                      {data.metadata?.CLOTH_NUMBER && (
                        <span className={`SportEvent__market-item-number`}>
                          {data.metadata?.CLOTH_NUMBER &&
                            data.metadata?.CLOTH_NUMBER}
                        </span>
                      )}
                      <span>{data?.name || data?.fancy_name}</span>
                      {data.GameStatus === 'REMOVED' && (
                        <span>{data?.GameStatus}</span>
                      )}
                    </p>
                    {showJockeyName && data?.metadata?.JOCKEY_NAME && (
                      <p>
                        {data.metadata?.STALL_DRAW && (
                          <span className={`SportEvent__market-item-number`}>
                            (
                            {data.metadata?.STALL_DRAW &&
                              data.metadata?.STALL_DRAW}
                            )
                          </span>
                        )}
                        <span
                          className={`jockeyName__name ${
                            !data.metadata?.STALL_DRAW &&
                            showJockeyName &&
                            data?.metadata?.JOCKEY_NAME
                              ? 'STALL_DRAW-missed'
                              : ''
                          } `}
                        >
                          {showJockeyName &&
                            data?.metadata?.JOCKEY_NAME &&
                            data?.metadata?.JOCKEY_NAME}
                        </span>
                      </p>
                    )}
                  </div>
                  {renderExposure(data?.id)}
                </div>
                {data?.GameStatus === 'WINNER' && (
                  <span className={`SportEvent__market-showStatus`}>
                    Winner
                  </span>
                )}
                {data?.tabGroupName === 'Fancy' && data?.betDelay && (
                  <div className="SportMarketStandard__title-timer">
                    <img
                      className="SportMarketStandard__title-timer-img"
                      src={timerIcon}
                      alt="timer icon"
                      loading={'lazy'}
                    />
                    <span className="SportMarketStandard__title-timer-delay">
                      {data.betDelay}s
                    </span>
                  </div>
                )}
                {renderInningIcon()}
              </div>
              {/*{renderOdds(item, runner)}*/}
              {isRacing && data && data?.status === 'ACTIVE'
                ? renderOdds(data, data)
                : ''}
              {!isRacing && renderOdds(data)}
            </div>
            {renderBetPlacing(data)}
          </div>
        </div>
      )}

      {openPopUp && (
        <SportMarketStandardPopUp
          onDidDismiss={() => setOpenPopUp(false)}
          title={popUpTitle}
        />
      )}
    </section>
  )
}

export default connectSportMarketStandard()(SportMarketStandard)
