import { IonButton, IonIcon } from '@ionic/react'
import { useSelector, useDispatch } from 'react-redux'
import React from 'react'
import { useHistory } from 'react-router'
import { setLoginNew } from '../../../../store/LoginNew/loginNew.slice'
import { setUrlForSportbook } from '../../../../store/betbyWidget/urlForSportbook.slice'
import {
  requestCasinoPoint,
  requestLiveCasinoID,
  requestQTechCasinoID,
} from '../../../../store/liveCasino/liveCasino.thunks'
import { setQuickButtonSlots } from '../../../../store/slotsGames/slotsGames.slice'
import { setSlotCasinoPopUp } from '../../../../store/slotCasinoPopUp/slotCasinoPopUp.slice'
import ReactGA from 'react-ga4'
import { getMenuSuccess } from '../../../../store/menu/menu.slice'
import {
  setIsAnimatedMenu,
  setIsDetailedGroup,
  setIsMoreDetailed,
  setIsNavigationID,
  setIsNavigationName,
  setIsNavigationOpen,
  setRenderIonContent,
} from '../../../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { requestMenu } from '../../../../store/menu/menu.thunks'
import { USER_DATA } from '../../../../config/app.config'
import { toastr } from 'react-redux-toastr'

const QuickButton = ({
  name,
  icon,
  redirect,
  backgroundImage,
  backgroundColour,
  isSlide,
  id,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const elementsParams = useSelector((state) => state.elementsParams)

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

  const gotoEvent = () => {
    // if(auth || name === "Sportsbook") {
    //     history.push(redirect)
    // }
    if (!auth) {
      dispatch(setLoginNew(true))
      return
    }

    let user = localStorage.getItem(USER_DATA)
    let userData = JSON.parse(user)
    if (userData?.is_demo) {
      toastr.error('', 'Please login from your read ID.')
      return
    }

    if (redirect.startsWith('http')) {
      window.location.href = redirect
      return
    }

    if (redirect === 'SPB-aviator') {
      onSelectGame(redirect)
      dispatch(setSlotCasinoPopUp(true))
      dispatch(requestCasinoPoint())
    }

    if (redirect.split('=')[1]) {
      dispatch(setUrlForSportbook(redirect.split('=')[1]))
    } else {
      dispatch(setUrlForSportbook(null))
    }

    if (redirect.split('/')[1] === 'sports') {
      openGame('QT', redirect.split('/')[2])
      return
    }

    // if (redirect.split('/')[2] === '70011' || redirect.split('/')[2] === '98789' || redirect.split('/')[2] === '70001' || redirect.split('/')[2] === '70008') {
    //   dispatch(setSlotCasinoPopUp(true))
    //   dispatch(requestCasinoPoint())
    //   }

    if (name.indexOf('Evolution') != -1) {
      dispatch(setSlotCasinoPopUp(true))
      dispatch(requestCasinoPoint())
      dispatch(requestLiveCasinoID(redirect))
    } else if (redirect.indexOf('slotsgames') != -1) {
      history.push('/slotsgames-lobby')
      dispatch(
        setQuickButtonSlots(redirect.slice(redirect.lastIndexOf('/') + 1))
      )
      dispatch(requestCasinoPoint())
      dispatch(setSlotCasinoPopUp(true))
    } else if (redirect.indexOf('livecasino') != -1) {
      // dispatch(requestCasinoPoint())
      // dispatch(setSlotCasinoPopUp(true))
      history.push('/livecasino-lobby')
    } else {
      history.push(redirect)
    }

    if (!auth && name.indexOf('Evolution') != -1) {
      dispatch(setLoginNew(true))
    } else if (redirect.indexOf('slotsgames') != -1) {
      dispatch(requestCasinoPoint())
      dispatch(setSlotCasinoPopUp(true))
      history.push('/slotsgames-lobby')
      dispatch(
        setQuickButtonSlots(redirect.slice(redirect.lastIndexOf('/') + 1))
      )
    } else if (redirect.indexOf('livecasino') != -1) {
      // dispatch(requestCasinoPoint())
      // dispatch(setSlotCasinoPopUp(true))
      history.push('/livecasino-lobby')
      dispatch(
        setQuickButtonSlots(redirect.slice(redirect.lastIndexOf('/') + 1))
      )
    } else {
      history.push(redirect)
    }
  }

  const onSelectGame = (id) => {
    if (auth) {
      let data = { gameId: id, providerCode: 'SPB' }
      dispatch(requestQTechCasinoID(data))
      history.push(`/live_casino_slot/${id}`)
      // dispatch(openSlotCasinoPopUp());
    } else {
      dispatch(setLoginNew(true))
      // dispatch(setLoginNavigationData({ page: 'casino_live', ids: [id, tableId] }));
    }
  }

  const openGame = (sport_id, id) => {
    dispatch(getMenuSuccess([]))
    {
      if (sport_id === 'QT' || sport_id === '-100') {
        dispatch(setSlotCasinoPopUp(true))
        dispatch(requestCasinoPoint())
      }

      dispatch(setIsNavigationOpen(true))
      dispatch(setRenderIonContent(false))
      // dispatch(setIsAnimatedMenu(true)
      setTimeout(() => {
        dispatch(setIsNavigationOpen(false))
      }, 400)
      setTimeout(() => {
        dispatch(setIsAnimatedMenu(false))
      }, 400)
      dispatch(setIsNavigationID(sport_id))
      dispatch(setIsNavigationName('QTech'))
      dispatch(setIsMoreDetailed(id))
      dispatch(setIsDetailedGroup(''))
    }

    if (width < 1024) {
      let page = `/menu-event/${sport_id}/${id}`
      history.push({
        pathname: page,
        state: { id: sport_id },
      })
    } else {
      history.push('/sports-page')
    }
  }

  return (
    <IonButton className="promotion-button" mode="md" id={id}>
      <div
        className="promotion-button__content"
        onClick={
          isSlide
            ? () => {}
            : () => {
                ReactGA.event({
                  label: 'Quick-Buttons',
                  category: 'User-Click',
                  action: name + '_Quick_Buttons',
                })
                gotoEvent()
              }
        }
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: `${backgroundColour}`,
        }}
      >
        <div
          className="promotion-button__icon"
          style={{ backgroundImage: `url(${icon})` }}
        ></div>
        {name}
      </div>
    </IonButton>
  )
}

export default QuickButton
